import { gql } from "graphql-request";
import SectionHeaderFragment from "queries/fragments/SectionHeader";
import RichTextFragment from "queries/fragments/RichText";

const PAGE_QUERY = gql`
  ${SectionHeaderFragment}
  ${RichTextFragment}

  query StaticPage($slug: String!) {
    staticPages(where: { Slug: $slug, Site: "opencoaster" }) {
      Name
      Slug
      Sections {
        ...SectionHeader
        ...RichText
      }
    }
  }
`;

export default PAGE_QUERY;
