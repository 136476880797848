export default function SectionHeader({ section }) {
  return (
    <div data-section-type={section.__typename}>
      <h2
        id={section.Anchor}
        className="mb-3 font-serif text-3xl text-gray-800"
      >
        {section.Header}
      </h2>
    </div>
  );
}
