import Head from "next/head";
import useSWR from "swr";
import { useRouter } from "next/router";
import Throw404 from "components/throw404";
import Sections from "components/sections";
import Breadcrumb from "components/global/breadcrumbs";
import PAGE_QUERY from "queries/page";

export default function PageDetail() {
  const router = useRouter();

  const variables = {
    slug: router.query.page,
  };

  const { data, error } = useSWR([PAGE_QUERY, variables]);

  if (error) {
    console.log(error);
    return <Throw404 />;
  }

  if (!Array.isArray(data?.staticPages) || !data?.staticPages?.length) {
    return <Throw404 />;
  }

  const page = data?.staticPages[0];

  const breadcrumbs = [
    {
      name: page.Name,
      link: "/[page]",
      linkAs: `/${page.Slug}`,
    },
  ];

  return (
    <>
      <Head>
        <title>{page.Name} | Open Coaster</title>
      </Head>
      <main className="container m-6 mx-auto px-6 sm:px-0">
        <Breadcrumb items={breadcrumbs} />
        <Sections sections={page.Sections} />
      </main>
    </>
  );
}
