import { unstable_serialize } from "swr";
import query from "utils/query";
import PageDetail from "components/page";
import Config from "components/config";

// used for get static props
import PAGE_QUERY from "queries/page";
import PAGES_QUERY from "queries/pages";

export default function Page({ fallback }) {
  return (
    <Config fallback={fallback}>
      <PageDetail />
    </Config>
  );
}

export async function getStaticPaths() {
  const pages = await query(PAGES_QUERY);
  const paths = pages?.staticPages?.map((page) => ({
    params: { page: page?.Slug },
  }));

  return { paths, fallback: "blocking" };
}

export async function getStaticProps({ params }) {
  const fallback = {};

  const variables = {
    slug: params?.page,
  };

  const data = await Promise.all([query(PAGE_QUERY, variables)]);

  fallback[[unstable_serialize([PAGE_QUERY, variables])]] = data[0];

  return {
    props: {
      fallback,
    },
  };
}
