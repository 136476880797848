import { gql } from "graphql-request";

const SectionHeader = gql`
  fragment SectionHeader on ComponentTextSectionHeader {
    __typename
    Header
    Anchor
    id
  }
`;

export default SectionHeader;
