import SectionHeader from "components/sections/SectionHeader";
import SectionText from "components/sections/SectionText";

function Sections({ sections, className = "mb-10", ...props }) {
  if (!sections || !sections.length) {
    return null;
  }

  return (
    <div className={className} {...props}>
      {sections.map((section) => {
        if (section.__typename === "ComponentTextSectionHeader") {
          return <SectionHeader section={section} key={section.id} />;
        }
        if (section.__typename === "ComponentTextText") {
          return <SectionText section={section} key={section.id} />;
        }

        console.log("UNUSED SECTION", { section });
        return <></>;
      })}
    </div>
  );
}

export default Sections;
