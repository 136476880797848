import { gql } from "graphql-request";

const RichText = gql`
  fragment RichText on ComponentTextText {
    __typename
    Content
    id
  }
`;

export default RichText;
