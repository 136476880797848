import ReactMarkdown from "react-markdown";

export default function SectionText({ section }) {
  return (
    <div data-section-type={section.__typename}>
      <div className="prose mb-3 lg:prose-xl">
        <ReactMarkdown>{section.Content}</ReactMarkdown>
      </div>
    </div>
  );
}
